.linkButton {
  background: linear-gradient(90deg, #f54f6b 0%, #d756b0 100%);

  img,
  path {
    color: white !important;
  }
  .svgIcon div {
    margin: 13px auto;
  }
  svg {
    width: 33px;
    margin: 0 auto;
    display: block;
  }
  .imgGrid {
    margin: 0;
  }
  .gridItem {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    color: white !important;
  }
}
.thirdButton {
  margin: 15px;
  width: fit-content;
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
  box-shadow: 3px 3px 6px #d5d5d5, -3px -3px 6px #fff;

  border-radius: 9px;
  &:hover {
    background-color: #ccc;
    box-shadow: inset 1px 1px 4px #aaa;
  }
}
.singleFile {
  .imageContainer {
    cursor: pointer;
    box-shadow: 1px 1px 3px #444;
    position: relative;
    border-radius: 9px;

    .imageButton {
      margin: auto;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: fit-content;
    }
    i {
      font-size: 30px;
      display: block;
      margin: 0 auto;
      color: var(--pink);
    }
  }
}
.logo {
  svg {
    * {
      fill: white !important;
    }
  }
}
