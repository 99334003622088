.customerView{
    .clientList {
        &.s,&.xs{
            order:2;
        }
    }
    .loanList {
        &.s,&.xs{
            order:1;
        }
    }
}