.loanTable {
  text-transform: capitalize;
  .pagado {
    background-color: darkgreen;
    color: white;
  }
  .autorizado {
    color: white;
    background-color: darkblue;
  }
  .cancelado {
    color: white;
    background-color: red;
  }
  .pendiente {
    color: white;
    background-color: orange;
  }
}
*:focus {
  outline: none;
}
